import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
import { IoMdAddCircleOutline } from "react-icons/io";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
//Srcs
import "../Style/pagestyle.scss";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import {
  GET_CHART_OF_ACCOUNT_WITH_PAGINATION,
  GET_ACCOUNT_TYPE,
} from "../Schema/ChartOfAccount";
import FooterPagination from "../Components/Include/FooterPagination";
import CreateChartofAccount from "../Components/ChartofAccount/CreateChartofAccount";
import ChartofAccountAction from "../Components/ChartofAccount/ChartofAccountAction";
import BalanceRow from "../Components/ChartofAccount/BalanceRow";

export default function ChartOfAccount() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [departmentVal, setDepartmentVal] = useState(
    JSON.parse(window.localStorage.getItem("departmentVal"))
  );
  // console.log("departmentVal::", departmentVal)
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // ======================= End resize width Screen ======================
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [keyword, setKeyword] = useState("");
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [accountTypeData, setAccountTypeData] = useState([]);
  const [accountTypeSelected, setAccountTypeSelected] = useState("");

  const handleSearch = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  const { refetch } = useQuery(GET_CHART_OF_ACCOUNT_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      departmentId: [departmentVal?.id ? departmentVal?.id : ""],
      accountType: accountTypeSelected ? accountTypeSelected : "",
    },
    onCompleted: ({ getChartOfAccountWithPagination }) => {
      setTableData(getChartOfAccountWithPagination.data);
      setPaginationData(getChartOfAccountWithPagination?.paginator);
      setLoading(false);
      // console.log(
      //   "getChartOfAccountWithPagination::",
      //   getChartOfAccountWithPagination
      // );
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  // ========================== get account type =================================
  const { refetch: refetchAccountType } = useQuery(GET_ACCOUNT_TYPE, {
    onCompleted: ({ getAccountType }) => {
      // console.log("getAccountType::", getAccountType);
      setAccountTypeData(getAccountType);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">
            {t("page_chart_of_account")}
          </Typography>
          <Typography className="page-subtitle">
            Manage chart of account
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={0.6}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/">
              {t("page_dashboard")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">
              {t("page_chart_of_account")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid item container spacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <Typography className="search-head-title">
              Search account name
            </Typography>
            <TextField
              placeholder={t("search")}
              size="small"
              className="search-field"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={3}>
            <Typography className="search-head-title">
              Select account type
            </Typography>
            <FormControl fullWidth className="search-field">
              <Select
                size="small"
                value={accountTypeSelected}
                onChange={(e) => setAccountTypeSelected(e.target.value)}
              >
                {accountTypeData?.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={<IoMdAddCircleOutline />}
              >
                {t("add_account")}
              </Button>
              <CreateChartofAccount
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">
                {t("thead_code")}
              </TableCell>
              <TableCell className="header-title">
                {t("thead_account_name")}
              </TableCell>
              <TableCell className="header-title">
                {t("thead_account_type")}
              </TableCell>
              <TableCell className="header-title">
                {t("thead_balance")}
              </TableCell>
              <TableCell className="header-title-end"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body-row">
                  <TableCell colSpan={5} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData?.length === 0 ? (
                  <TableRow className="body-row">
                    <TableCell colSpan={5} className="body-cell">
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TableRow className="body-row">
                            <TableCell className="body-cell-start">
                              {row?.code_account
                                ? row?.code_account
                                : "---:---"}
                            </TableCell>
                            <TableCell className="body-cell">
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <RadioButtonCheckedIcon
                                  sx={{ fontSize: "10px", color: "#027816" }}
                                />
                                <Stack>{row?.account_name}</Stack>
                              </Stack>
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.account_type}
                            </TableCell>
                            <BalanceRow
                              chartAccountId={row?._id}
                              type="main-account"
                            />
                            <TableCell className="body-cell-end" align="right">
                              <ChartofAccountAction
                                actionType="mainAction"
                                editData={row}
                                setRefetch={refetch}
                              />
                            </TableCell>
                          </TableRow>
                          {row?.sub_account?.length !== 0 ? (
                            <>
                              {row?.sub_account?.map((sub, index1) => (
                                <TableRow className="body-row" key={index1}>
                                  <TableCell className="body-cell-start">
                                    {row?.code_account
                                      ? row?.code_account
                                      : "---:---"}
                                  </TableCell>
                                  <TableCell className="body-cell">
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <Stack marginLeft={4}>
                                        <PanoramaFishEyeIcon
                                          sx={{ fontSize: "10px" }}
                                        />
                                      </Stack>
                                      <Stack>{sub?.account_name}</Stack>
                                    </Stack>
                                  </TableCell>
                                  <TableCell className="body-cell">
                                    {sub?.account_type}
                                  </TableCell>
                                  <BalanceRow
                                    chartAccountId={sub?._id}
                                    type="sub-account"
                                  />
                                  <TableCell
                                    className="body-cell-end"
                                    align="right"
                                  >
                                    <ChartofAccountAction
                                      actionType="subAction"
                                      editData={row}
                                      editDataSub={sub}
                                      setRefetch={refetch}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          ) : null}
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* ==================================== Pagination ====================== */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
