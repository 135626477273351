import { gql } from "@apollo/client";

export const GET_BALANCE_SHEET_REPORT = gql`
  query BalanceSheetReport($fromDate: String, $toDate: String) {
  balanceSheetReport(fromDate: $fromDate, toDate: $toDate) {
      asset {
        account_name
        sub_account {
          account_name
          total_balance {
            current_month_balance
          }
        }
        total_balance {
          current_month_balance
        }
      }
      total_asset {
        current_month_balance
      }
      liability {
        account_name
        sub_account {
          account_name
          total_balance {
            current_month_balance
          }
        }
        total_balance {
          current_month_balance
        }
      }
      total_liability {
        current_month_balance
      }
      equity {
        account_name
        sub_account {
          account_name
          total_balance {
            current_month_balance
          }
        }
        total_balance {
          current_month_balance
        }
      }
      total_equity {
        current_month_balance
      }
      total_liability_and_equity {
        current_month_balance
      }
  }
}
`;

export const INCOME_STATEMENT_REPORT = gql`
  query IncomeStatementReport(
    $departmentId: String
    $fromDate: Date
    $toDate: Date
    $form: String
  ) {
    incomeStatementReport(
      department_id: $departmentId
      fromDate: $fromDate
      toDate: $toDate
      form: $form
    ) {
      revenues {
        account_name
        selectedDateBalance
        yearToDateBalance
      }
      totalRevenue {
        selectedDateBalance
        yearToDateBalance
      }
      costOfSales {
        account_name
        selectedDateBalance
        yearToDateBalance
      }
      totalCost {
        selectedDateBalance
        yearToDateBalance
      }
      expenses {
        account_name
        selectedDateBalance
        yearToDateBalance
      }
      totalExpense {
        selectedDateBalance
        yearToDateBalance
      }
      grossProfit {
        selectedDateBalance
        yearToDateBalance
      }
      netIncome {
        selectedDateBalance
        yearToDateBalance
      }
    }
  }
`;

export const GET_GENERAL_LEDGAR = gql`
  query GeneralLedgerReport($fromDate: Date, $toDate: Date) {
    generalLedgerReport(fromDate: $fromDate, toDate: $toDate) {
      details {
        _id
        account_name
        account_type
        sub_account {
          _id
          account_name
          account_type
          journal_entries {
            journal_number
            record_date
            transaction_title
            description
            memo
            debit
            credit
          }
          total_balance {
            debit
            credit
            balance
          }
        }
        total_balance {
          debit
          credit
          balance
        }
      }
      total {
        debit
        credit
        balance
      }
    }
  }
`;

export const CLOSE_REPORT = gql`
  mutation CloseReport($dateTime: String) {
    closeReport(dateTime: $dateTime) {
      isSuccess
      message
    }
  }
`;
