import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
//srcs
import EmptyData from "../Include/EmptyData";
import LoadingPage from "../Include/LoadingPage";
import { GET_GENERAL_LEDGAR } from "../../Schema/Report";
import { currencyFormat } from "../../Function/DynamicFn";

export default function GeneralLedger({ startDate, endDate }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_GENERAL_LEDGAR, {
    variables: {
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ generalLedgerReport }) => {
      // console.log("generalLedgerReport::", generalLedgerReport);
      setTableData(generalLedgerReport);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  return (
    <table className="report-container">
      {/* <thead> */}
      <tr>
        <th className="thead-account-ledger">Account</th>
        <th className="thead-memo">Memo</th>
        <th className="thead-debit">Debit</th>
        <th className="thead-debit">Credit</th>
      </tr>
      {/* </thead> */}
      {loading ? (
        <tr>
          <td colSpan={5}>
            <LoadingPage />
          </td>
        </tr>
      ) : (
        <Fragment>
          {tableData?.details?.length === 0 ? (
            <tr>
              <td colSpan={5}>
                <EmptyData />
              </td>
            </tr>
          ) : (
            <>
              {tableData?.details?.map((row, index) => {
                return (
                  <>
                    <tr className="report-body-row" key={index}>
                      <td
                        className="report-body-cell-header"
                        style={{ fontWeight: "bold" }}
                        colSpan={5}
                      >
                        {row?.account_name}
                      </td>
                    </tr>
                    {row?.sub_account?.length !== 0 ? (
                      <>
                        {row?.sub_account?.map((sub, index1) => {
                          return (
                            <>
                              <tr key={index1} className="report-body-row">
                                <td
                                  className="report-body-cell-statement-sub1"
                                  style={{
                                    padding: "8px 0px 8px 20px",
                                  }}
                                >
                                  {sub?.account_name}
                                </td>
                                <td className="report-body-cell"></td>
                                <td className="report-body-cell">
                                  {currencyFormat(sub?.total_balance?.debit)}
                                </td>
                                <td className="report-body-cell">
                                  {currencyFormat(sub?.total_balance?.credit)}
                                </td>
                              </tr>
                              {sub?.journal_entries?.length !== 0 ? (
                                <>
                                  {sub?.journal_entries?.map(
                                    (journal, index2) => {
                                      return (
                                        <tr
                                          key={index2}
                                          className="report-body-row"
                                        >
                                          <td
                                            className="report-body-cell-sub2"
                                            style={{
                                              padding: "8px 0px 8px 40px",
                                            }}
                                          >
                                            {moment(
                                              journal?.record_date
                                            ).format("DD-MMM-YYYY")}
                                          </td>
                                          <td className="report-body-cell">
                                            {journal?.memo}
                                          </td>
                                          <td className="report-body-cell">
                                            {currencyFormat(journal?.debit)}
                                          </td>
                                          <td className="report-body-cell">
                                            {currencyFormat(journal?.credit)}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </>
                    ) : null}
                    <tr className="report-body-row" key={index + 1}>
                      <td
                        className="report-body-cell-header"
                        style={{ fontWeight: "bold" }}
                      >
                        Total {row?.account_name}
                      </td>
                      <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      ></td>
                      <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(row?.total_balance?.debit)}
                      </td>
                      <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(row?.total_balance?.credit)}
                      </td>
                    </tr>
                  </>
                );
              })}

              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                  colSpan={2}
                ></td>
                <td className="report-body-cell-debit">
                  {currencyFormat(tableData?.total?.debit)}
                </td>
                <td className="report-body-cell-debit">
                  {currencyFormat(tableData?.total?.credit)}
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                ></td>
              </tr>
            </>
          )}
        </Fragment>
      )}
    </table>
  );
}
