import moment from "moment";
import { useEffect, useState } from "react";

export function get_date_filter(dateReport) {

    // console.log("dateReport",dateReport);    
    let fromDate ="";
    let toDate ="";

    // Date
    let now = new Date();

    function getFirstDayOfWeek(d) {
      // 👇️ clone date object, so we don't mutate it
      let dateFirst = new Date(d);
      let dayNum = dateFirst.getDay(); // 👉️ get day of week
      // console.log("day", day)
      // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
      let diffFirst = dateFirst.getDate() - dayNum;

      return new Date(dateFirst.setDate(diffFirst));
    }

    function getLastDayOfWeek(d) {
      // 👇️ clone date object, so we don't mutate it
      let dateLast = new Date(d);
      let dayNumLast = dateLast.getDay(); // 👉️ get day of week
  
      let diffLast = dateLast.getDate() - dayNumLast + 6;
  
      return new Date(dateLast.setDate(diffLast));
    }
   
    if (dateReport === "all") {
      fromDate = "";
      toDate= "";
    } else if (dateReport === "today") {
      fromDate = moment(now).format("YYYY-MM-DD");
      toDate = moment(now).format("YYYY-MM-DD");
    } else if (dateReport === "this_week") {        
      fromDate = moment(getFirstDayOfWeek(now)).format("YYYY-MM-DD");
      toDate = moment(getLastDayOfWeek(now)).format("YYYY-MM-DD");
    } else if (dateReport === "this_month") {          
      fromDate = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format("YYYY-MM-DD");
      toDate = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format("YYYY-MM-DD");
    } else if (dateReport === "this_year") {          
      fromDate = moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY-MM-DD");
      toDate = moment(new Date(new Date().getFullYear(), 11, 31)).format("YYYY-MM-DD");
    } else if (dateReport === "last_week") {
      const currentDate = moment().subtract(1, "weeks");
      let weekStart = currentDate.clone().startOf("week");
      let weekEnd = currentDate.clone().endOf("week");
      fromDate = moment(weekStart).format("YYYY-MM-DD");
      toDate = moment(weekEnd).format("YYYY-MM-DD");
    } else if (dateReport === "last_month") {         
      fromDate = moment(new Date(now.getFullYear(), now.getMonth() - 1, 1)).format("YYYY-MM-DD");
      toDate = moment(new Date(now.getFullYear(), now.getMonth(), 0)).format("YYYY-MM-DD");
    } else if (dateReport === "last_year") {          
      fromDate = moment(new Date(new Date().getFullYear() - 1, 0, 1)).format("YYYY-MM-DD");
      toDate = moment(new Date(new Date().getFullYear() - 1, 11, 31)).format("YYYY-MM-DD");
    }   
    return {fromDate,toDate};
}