import { gql } from "@apollo/client";
export const CREATE_DEPATMENT = gql`
  mutation CreateDepartment($input: DepartmentInput) {
    createDepartment(input: $input) {
      isSuccess
      message
    }
  }
`;
export const UPDATE_DEPENDENT = gql`
  mutation UpdateDepartment($departmentId: ID!, $input: DepartmentInput) {
    updateDepartment(department_id: $departmentId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_DEPENDENT = gql`
  mutation DeleteDepartment($departmentId: ID!) {
    deleteDepartment(department_id: $departmentId) {
      isSuccess
      message
    }
  }
`;

export const GET_DEPARTMENT_WITH_PAGINATION = gql`
  query GetDepartmentWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $userId: String
  ) {
    getDepartmentWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      userId: $userId
    ) {
      data {
        _id
        department_name
        description
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
