import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInput) {
    createUser(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_USER_WITH_PAGINATION = gql`
  query GetUserWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getUserWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        user_first_name
        user_last_name
        user_email
        user_image_name
        user_image_src
        role
        status
        createdAt
        updatedAt
        departments_access {
          _id
          department_name
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_USER_LOGIN = gql`
  query GetUserLogin {
    getUserLogin {
      _id
      user_first_name
      user_last_name
      user_email
      user_image_name
      user_image_src
      role
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UserInput) {
    updateUser(_id: $id, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(_id: $id) {
      isSuccess
      message
    }
  }
`;

export const ASSIGN_DEPARTMENT_TO_USER = gql`
  mutation AssignDepartment($userId: ID!, $departmentId: ID!) {
    assignDepartment(user_id: $userId, department_id: $departmentId) {
      isSuccess
      message
    }
  }
`;

export const DELETE_DEPARTMENT_FROM_USER = gql`
  mutation DeleteAssignedDepartment($userId: ID!, $departmentId: ID!) {
    deleteAssignedDepartment(user_id: $userId, department_id: $departmentId) {
      isSuccess
      message
    }
  }
`;
