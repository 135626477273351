import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { TableCell } from "@mui/material";
//==================srcs========================
import { currencyFormat } from "../../Function/DynamicFn";
import { GET_BALANCE_BY_CHART_ACCOUNT } from "../../Schema/ChartOfAccount";

export default function BalanceRow({ chartAccountId, type }) {
  const [loading, setLoading] = useState(true);
  const [balanceData, setBalanceData] = useState([]);
  const { refetch: refetchBalance } = useQuery(GET_BALANCE_BY_CHART_ACCOUNT, {
    variables: {
      chartAccountId: chartAccountId,
      startDate: null,
      endDate: null,
    },
    onCompleted: ({ getBalanceByChartAccountId }) => {
      // console.log("getBalanceByChartAccountId::", getBalanceByChartAccountId);
      setLoading(false);
      setBalanceData(getBalanceByChartAccountId);
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  return (
    <TableCell className="body-cell">
      {currencyFormat(balanceData?.total_balance)}
    </TableCell>
  );
}
