import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Grid,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Srcs
import "../../Style/pagestyle.scss";
import EmptyData from "../Include/EmptyData";
import LoadingPage from "../Include/LoadingPage";
import { currencyFormat, journalNumberFormat } from "../../Function/DynamicFn";
import { GET_JOURNAL_BY_ID } from "../../Schema/GeneralJournal";
import ViewGeneralJournalAction from "./ViewGeneralJournalAction";

export default function ViewGeneralJournal() {
  //get journal id from url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [journalId, setJournalId] = useState(params.get("journalId"));

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // ======================= End resize width Screen ======================

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_JOURNAL_BY_ID, {
    variables: {
      journalId: journalId,
    },
    onCompleted: ({ getJournalById }) => {
      // console.log("getJournalById>>>", getJournalById);
      setTableData(getJournalById);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  useEffect(() => {
    refetch();
  }, [journalId]);

  const totalDebit = tableData?.journal_entries?.reduce(
    (accumulator, object) => {
      return accumulator + object.debit;
    },
    0
  );

  const totalCredit = tableData?.journal_entries?.reduce(
    (accumulator, object) => {
      return accumulator + object.credit;
    },
    0
  );

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">View general journal</Typography>
          <Typography className="page-subtitle">
            View general journal details
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={0.6}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/">
              Dashboard
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/general-journal">
              General journal
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">
              View general journal
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box className="box-view-container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Stack direction="row" spacing={1}>
              <Typography className="view-text-name">
                Journal number :
              </Typography>
              <Typography className="view-text-item">
                {journalNumberFormat(tableData?.journal_number)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Stack direction="row" spacing={1}>
              <Typography className="view-text-name">Created by :</Typography>
              <Typography className="view-text-item">
                {tableData?.created_by?.user_first_name +
                  " " +
                  tableData?.created_by?.user_last_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Stack direction="row" spacing={1}>
              <Typography className="view-text-name">
                Transacion date :
              </Typography>
              <Typography className="view-text-item">
                {moment(tableData?.record_date).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Stack direction="row" spacing={1}>
              <Typography className="view-text-name">Memo :</Typography>
              <Typography className="view-text-item">
                {tableData?.note}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">ID</TableCell>
              <TableCell className="header-title">Type</TableCell>
              <TableCell className="header-title">Account</TableCell>
              <TableCell className="header-title">Description</TableCell>
              <TableCell className="header-title" align="right">
                Debit
              </TableCell>
              <TableCell className="header-title" align="right">
                Credit
              </TableCell>
              <TableCell className="header-title-end header-sticky-right"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body-row">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData?.journal_entries?.length === 0 ? (
                  <TableRow className="body-row">
                    <TableCell colSpan={6} className="body-cell">
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.journal_entries?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.chart_account_id?.account_type}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.chart_account_id?.account_name}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.description}
                          </TableCell>
                          <TableCell className="body-cell" align="right">
                            {currencyFormat(row?.debit)}
                          </TableCell>
                          <TableCell className="body-cell" align="right">
                            {currencyFormat(row?.credit)}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <ViewGeneralJournalAction
                              editData={tableData}
                              setRefetch={refetch}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    <TableRow className="body-row">
                      <TableCell
                        className="body-cell-start-details-journal"
                        colSpan={4}
                      >
                        {/* Total balance : */}
                      </TableCell>
                      <TableCell
                        className="body-cell-details-journal"
                        align="right"
                      >
                        {currencyFormat(totalDebit)}
                      </TableCell>
                      <TableCell
                        className="body-cell-details-journal"
                        align="right"
                      >
                        {currencyFormat(totalCredit)}
                      </TableCell>
                      <TableCell
                        className="body-cell-end"
                        align="right"
                      ></TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
