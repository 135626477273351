import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Stack, Typography, Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Srcs
import "./dashboard.scss";
import LoadingPage from "../Include/LoadingPage";
import EmptyData from "../Include/EmptyData";
import { currencyFormat } from "../../Function/DynamicFn";
import { GET_AR_AND_AP } from "../../Schema/Dashboard";

export default function AccountChart({ startDate, endDate }) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const { refetch } = useQuery(GET_AR_AND_AP, {
    variables: {
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ getARandAP }) => {
      setChartData(getARandAP);
      setLoading(false);
      // console.log("getARandAP::", getARandAP);
    },
    onError: (error) => {
      console.log(error?.message);
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  const state = {
    series: [
      {
        name: "Account Receivable",
        data: chartData?.map(({ balanceAR }) => balanceAR),
      },
      {
        name: "Account Payable",
        data: chartData?.map(({ balanceAP }) => balanceAP),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 0,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          chartData[0]?.month,
          chartData[1]?.month,
          chartData[2]?.month,
          chartData[3]?.month,
          chartData[4]?.month,
          chartData[5]?.month,
          chartData[6]?.month,
          chartData[7]?.month,
          chartData[8]?.month,
          chartData[9]?.month,
          chartData[10]?.month,
          chartData[11]?.month,
        ],
      },
      yaxis: {
        title: {
          text: "($)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return currencyFormat(val);
          },
        },
      },
    },
  };

  return (
    <div id="chart" className="dashboard-container">
      <Stack direction="column" spacing={1} className="account-chart-container">
        <Typography className="chart-title">
          Account Receivable and Account Payable
        </Typography>
        {loading ? (
          <Box className="account-chart">
            <LoadingPage />
          </Box>
        ) : (
          <>
            {chartData?.length === 0 ? (
              <Box className="account-chart">
                <EmptyData />
              </Box>
            ) : (
              <Box className="account-chart">
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type="bar"
                  height={380}
                />
              </Box>
            )}
          </>
        )}
      </Stack>
    </div>
  );
}
