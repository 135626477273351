import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
//Date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Icons
import SearchIcon from "@mui/icons-material/Search";
import { IoMdAddCircleOutline } from "react-icons/io";
import moment from "moment";
// src
import "../Style/pagestyle.scss";
import { currencyFormat, journalNumberFormat } from "../Function/DynamicFn";
import { GET_JOURNAL_WITH_PAGINATION } from "../Schema/GeneralJournal";
import GeneralJournalAction from "../Components/GeneralJournal/GeneralJournalAction";
import CreateGeneralJournal from "../Components/GeneralJournal/CreateGeneralJournal";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import { useQuery } from "@apollo/client";
import FooterPagination from "../Components/Include/FooterPagination";

export default function GeneralJournal() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  let navigate = useNavigate();
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // ======================= End resize width Screen ======================
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  const handleSearch = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };

  const { refetch } = useQuery(GET_JOURNAL_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ getJournalWithPagination }) => {
      // console.log("getJournalWithPagination", getJournalWithPagination);
      setTableData(getJournalWithPagination?.data);
      setPaginationData(getJournalWithPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err);
    },
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  const handleViewJournal = (id) => {
    navigate(`/general-journal/view-general-journal?journalId=${id}`);
  };

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">
            {t("page_general_journal")}
          </Typography>
          <Typography className="page-subtitle">
            Manage journal entry
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={0.6}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/">
              {t("page_dashboard")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">
              {t("page_general_journal")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sx={6} md={4} lg={3} xl={3}>
            <TextField
              placeholder="Search memo"
              size="small"
              className="search-field"
              onChange={(e) => handleSearch(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} sx={6} md={8} lg={9} xl={9}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={<IoMdAddCircleOutline />}
              >
                {t("add_entry")}
              </Button>
              <CreateGeneralJournal
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
                userLogin={userLogin}
              />
            </Stack>
          </Grid>

          <Grid item xs={6} sx={6} md={4} lg={3} xl={3}>
            <Typography className="search-head-title">
              Select from date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd-MM-yyyy"
                value={startDate !== "" ? startDate : ""}
                onChange={(e) => setStartDate(e)}
                className="search-field"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6} sx={6} md={4} lg={3} xl={3}>
            <Typography className="search-head-title">
              Select to date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd-MM-yyyy"
                value={endDate !== "" ? endDate : ""}
                onChange={(e) => setEndDate(e)}
                className="search-field"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">
                {t("thead_journal_number")}
              </TableCell>
              <TableCell className="header-title">
                {t("thead_transaction_date")}
              </TableCell>
              <TableCell className="header-title">{t("thead_memo")}</TableCell>
              <TableCell className="header-title">
                {t("thead_balance")}
              </TableCell>
              <TableCell className="header-title">
                {t("thead_create_by")}
              </TableCell>
              <TableCell className="header-title-end header-sticky-right"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body-row">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData?.length === 0 ? (
                  <TableRow className="body-row">
                    <TableCell colSpan={6} className="body-cell">
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell
                            className="body-cell-start"
                            onClick={() => handleViewJournal(row?._id)}
                          >
                            {journalNumberFormat(row?.journal_number)}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleViewJournal(row?._id)}
                          >
                            {moment(row.record_date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleViewJournal(row?._id)}
                          >
                            {row?.memo}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleViewJournal(row?._id)}
                          >
                            {currencyFormat(
                              row?.journal_entries
                                ?.map((item) => item?.credit)
                                ?.reduce((prev, next) => prev + next)
                            )}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleViewJournal(row?._id)}
                          >
                            {row?.created_by?.user_first_name +
                              " " +
                              row?.created_by?.user_last_name}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <GeneralJournalAction
                              editData={row}
                              setRefetch={refetch}
                              userLogin={userLogin}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
