import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Srcs
//Srcs
import "./dashboard.scss";
import LoadingPage from "../Include/LoadingPage";
import EmptyData from "../Include/EmptyData";
import { currencyFormat } from "../../Function/DynamicFn";
import { GET_EXPENSE_BY_DEPARTMENT } from "../../Schema/Dashboard";

export default function ExpenseTable({ startDate, endDate, departmentId }) {
  const [loading, setLoading] = useState(true);
  const [expenseData, setExpenseData] = useState({});
  const { refetch } = useQuery(GET_EXPENSE_BY_DEPARTMENT, {
    variables: {
      departmentId: departmentId,
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ getExpenseByDepartment }) => {
      setExpenseData(getExpenseByDepartment);
      setLoading(false);
      // console.log("getExpenseByDepartment::", getExpenseByDepartment);
    },
    onError: (error) => {
      console.log(error?.message);
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate, departmentId]);

  return (
    <div className="dashboard-container">
      <Box className="expense-table">
        {/* <Typography className="table-title">
          Expense table
        </Typography> */}
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell className="header-title-start">N°</TableCell>
                <TableCell className="header-title">Expense</TableCell>
                <TableCell className="header-title-end" align="right">
                  Balance
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {expenseData?.length === 0 ? (
                  <TableRow className="body-row">
                    <TableCell colSpan={5} className="body-cell">
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {expenseData?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row.account_name}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            {currencyFormat(row.balance)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
