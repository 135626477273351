import React, { useState, useEffect } from "react";
import { Grid, Stack, Typography, IconButton } from "@mui/material";
import { useQuery } from "@apollo/client"
import moment from "moment";
//Srcs
import "./dashboard.scss";
import CaseHand from "../../Assets/bank.png";
import CaseBank from "../../Assets/cash.png";
import { currencyFormat } from "../../Function/DynamicFn";
import { GET_TOTAL_CASH } from "../../Schema/Dashboard";

export default function TotalCase({ startDate, endDate, departmentId }) {
  const [totalCashData, setTotalCashData] = useState({});
  const { refetch } = useQuery(GET_TOTAL_CASH, {
    variables: {
      departmentId: departmentId,
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ getCash }) => {
      setTotalCashData(getCash);
      // console.log("getCash::", getCash);
    },
    onError: (error) => {
      console.log(error?.message);
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate, departmentId]);

  return (
    <div className="dashboard-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
          <Stack
            className="total-case-bank"
            direction="column"
            justifyContent="space-between"
          >
            <Stack direction="row" justifyContent="center">
              <IconButton className="icon-container">
                <img src={CaseBank} alt="" className="icon-money" />
              </IconButton>
            </Stack>
            <Typography className="amount-number">
              {currencyFormat(
                totalCashData?.cashInBank ? totalCashData?.cashInBank : 0
              )}
            </Typography>
            <Typography className="card-des">Total case in bank</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
          <Stack
            className="total-case-hand"
            direction="column"
            justifyContent="space-between"
          >
            <Stack direction="row" justifyContent="center">
              <IconButton className="icon-container">
                <img src={CaseHand} alt="Icon" className="icon-money" />
              </IconButton>
            </Stack>
            <Typography className="amount-number">
              {currencyFormat(
                totalCashData?.cashOnHand ? totalCashData?.cashOnHand : 0
              )}
            </Typography>
            <Typography className="card-des">Total case on hand</Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
