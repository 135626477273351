import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
//Icons
import SearchIcon from "@mui/icons-material/Search";
import { IoMdAddCircleOutline } from "react-icons/io";
//Srcs
import "../Style/pagestyle.scss";
import ExpenseTypeAction from "../Components/ExpenseType/ExpenseTypeAction";
import CreateExpenseType from "../Components/ExpenseType/CreateExpenseType";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import FooterPagination from "../Components/Include/FooterPagination";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import { GET_EXPENSE_TYPE_WITH_PAGINATION } from "../Schema/ExpenseType";

export default function ExpenseType() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  // ======================= Resize width Screen ========================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // ======================= End resize width Screen ======================
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [keyword, setKeyword] = useState("");
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  const handleSearch = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };

  const { data, refetch } = useQuery(GET_EXPENSE_TYPE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
    },
    onCompleted: ({ getExpenseTypePagination }) => {
      setLoading(false);
      setTableData(getExpenseTypePagination?.data);
      setPaginationData(getExpenseTypePagination?.paginator);
    },
    onError: (error) => {
      console.log("onError", error);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Expense type</Typography>
          <Typography className="page-subtitle">Manage expense type</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={0.6}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/">
              {t("page_dashboard")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/setting">
              {t("page_setting")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">
              Expense type
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <TextField
              placeholder="Search expense name"
              size="small"
              className="search-field"
              onChange={(e) => handleSearch(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} md={8} lg={9}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add expense type
              </Button>
              <CreateExpenseType
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
                dialogTitle="Create"
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row">
              <TableCell className="header-title-start">{t("no")}</TableCell>
              <TableCell className="header-title">Expense type</TableCell>
              <TableCell className="header-title-end"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body-row">
                  <TableCell colSpan={5} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData?.length === 0 ? (
                  <TableRow className="body-row">
                    <TableCell colSpan={5} className="body-cell">
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row.expense_name}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <ExpenseTypeAction
                              setRefetch={refetch}
                              editData={row}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
