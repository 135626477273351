import React, { useState, useContext } from "react";
import { IconButton, Stack, Box } from "@mui/material";
//Icons
import { MdModeEdit, MdDelete } from "react-icons/md";
//Srcs
import "../../Style/actionstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import UpdateChartofAccount from "./UpdateChartofAccount";
import UpdateSubChartofAccount from "./UpdateSubChartofAccount";
import DeleteChartofAccount from "./DeleteChartofAccount";

export default function ChartOfAccountAction({
  actionType,
  editData,
  editDataSub,
  setRefetch,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <IconButton onClick={handleOpenEdit}>
          <Box className="update-container">
            <MdModeEdit className="update-icon" />
          </Box>
        </IconButton>
        <IconButton onClick={handleOpenDelete}>
          <Box className="delete-container">
            <MdDelete className="delete-icon" />
          </Box>
        </IconButton>

        {actionType === "mainAction" ? (
          <UpdateChartofAccount
            open={openEdit}
            handleClose={handleCloseEdit}
            editData={editData}
            setRefetch={setRefetch}
          />
        ) : (
          <UpdateSubChartofAccount
            open={openEdit}
            handleClose={handleCloseEdit}
            editDataMain={editData}
            editDataSub={editDataSub}
            setRefetch={setRefetch}
          />
        )}
        <DeleteChartofAccount
          open={openDelete}
          handleClose={handleCloseDelete}
          editData={actionType === "mainAction" ? editData : editDataSub}
          setRefetch={setRefetch}
        />
      </Stack>
    </div>
  );
}
