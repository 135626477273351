import React, { useContext, useState, useMemo, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, formik, FormikProvider } from "formik";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  IconButton,
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  TextField,
  TableCell,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//Date picker
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { UPDATE_JOURNAL } from "../../Schema/GeneralJournal";
import { currencyFormat } from "../../Function/DynamicFn";
import ListJournalEntry from "./ListJournalEntry";
import { journalNumberFormat } from "../../Function/DynamicFn";

export default function UpdateGeneralJournal({
  open,
  handleClose,
  editData,
  setRefetch,
  userLogin,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [requiredField, setRequiredField] = useState(false);

  // ========================================= List journal =========================================
  const [currentJournal, setCurrentJournal] = useState({
    key: "",
    chart_account_id: "",
    chart_account_name: "",
    description: "",
    debit: "",
    credit: "",
  });
  const [journalEntryList, setJournalEntryList] = useState([]);
  // console.log("journalEntryList", journalEntryList);
  const addJournalEntry = () => {
    const newItem = currentJournal;

    if (newItem !== "") {
      const items = [...journalEntryList, newItem];
      setJournalEntryList([...items]);
      setCurrentJournal({
        key: "",
        chart_account_id: "",
        chart_account_name: "",
        description: "",
        debit: 0,
        credit: 0,
      });
    }
  };

  const handleAddJournalEntryList = () => {
    setCurrentJournal({
      chart_account_id: "",
      chart_account_name: "",
      description: "",
      debit: 0,
      credit: 0,
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentJournal?.key !== "") {
      addJournalEntry();
    }
  }, [currentJournal]);

  //delete function
  const deleteJournalEntryList = (key) => {
    const filtereditems = journalEntryList?.filter((t) => t.key !== key);
    setJournalEntryList(filtereditems);
  };

  const setUpdateChartAccount = (chart_account_id, chart_account_name, key) => {
    const items = journalEntryList;
    items.map((i) => {
      if (i.key == key) {
        i.chart_account_id = chart_account_id;
        i.chart_account_name = chart_account_name;
      }
    });
    setJournalEntryList([...items]);
    if (chart_account_id !== undefined && chart_account_id !== "") {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }
  };
  const setUpdateDescription = (description, key) => {
    const items = journalEntryList;
    items.map((i) => {
      if (i.key == key) {
        i.description = description;
      }
    });
    setJournalEntryList([...items]);
    if (description !== "") {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }
  };
  const setUpdateDebit = (debit, key) => {
    const items = journalEntryList;
    items.map((i) => {
      if (i.key == key) {
        i.debit = parseFloat(debit);
      }
    });
    setJournalEntryList([...items]);
    if (debit !== undefined && debit !== "" && debit !== 0) {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }
  };

  const setUpdateCredit = (credit, key) => {
    const items = journalEntryList;
    items.map((i) => {
      if (i.key == key) {
        i.credit = parseFloat(credit);
      }
    });
    setJournalEntryList([...items]);
    if (credit !== undefined && credit !== "" && credit !== 0) {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }
  };
  // console.log("userLogin::", userLogin)
  //update journal
  const [updateJournal] = useMutation(UPDATE_JOURNAL, {
    onCompleted: ({ updateJournal }) => {
      if (updateJournal?.isSuccess) {
        setAlert(true, "success", updateJournal?.message);
        setRefetch();
        setLoading(false);
        handleClose();
      } else {
        setAlert(false, "error", updateJournal?.message);
      }
    },
    onError: (err) => {
      console.log("error", err);
    },
  });
  // formik
  const UpdateJournal = Yup.object().shape({
    record_date: Yup.date(),
    memo: Yup.string(),
    currency: Yup.string(),
    created_by: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      record_date: moment(),
      memo: "",
      currency: "USD",
      created_by: userLogin?._id,
    },
    validationSchema: UpdateJournal,
    onSubmit: async (values) => {
      let createStatus = false;
      const BreakException = {};
      try {
        if (journalEntryList?.length !== 0) {
          journalEntryList?.forEach(function (elem) {
            if (
              elem?.chart_account_id !== undefined &&
              elem?.chart_account_id !== "" &&
              elem?.debit !== undefined &&
              elem?.debit !== "" &&
              elem?.credit !== undefined &&
              elem?.credit !== ""
            ) {
              createStatus = true;
            } else {
              setLoading(false);
              createStatus = false;
              throw BreakException;
            }
          });
        } else {
          setLoading(false);
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      if (createStatus && sumDebit === sumCredit) {
        setLoading(true);
        let journal_entries = [];
        journalEntryList?.map((d) => {
          const allJournalEntries = {
            chart_account_id: d?.chart_account_id,
            credit: d?.credit,
            debit: d?.debit,
            description: d?.description,
            key: d?.key,
          };
          journal_entries?.push(allJournalEntries);
        });

        // console.log("values::". values)

        updateJournal({
          variables: {
            journalId: editData?._id,
            input: {
              ...values,
              record_date: moment(values?.record_date).format("YYYY-MM-DD"),
              journal_entries: journal_entries,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setFieldValue("record_date", editData?.record_date);
      setFieldValue("memo", editData?.memo);
    }
    if (editData?.journal_entries?.length !== 0) {
      let journal_entries = [];

      editData?.journal_entries.map((d, index) => {
        const allJournalEntries = {
          chart_account_id: d?.chart_account_id?._id,
          chart_account_name:
            d?.chart_account_id?.parents_account === null
              ? d?.chart_account_id?.account_name
              : d?.chart_account_id?.parents_account?.account_name +
                ": " +
                d?.chart_account_id?.account_name,
          // chart_account_name: d?.chart_account_id?.account_name,
          description: d?.description,
          debit: d?.debit,
          credit: d?.credit,
          key: index + Date.now(),
        };
        journal_entries?.push(allJournalEntries);
        setJournalEntryList(journal_entries);
      });
    }
  }, [editData]);

  // console.log("journalEntryList::", journalEntryList);

  //================start sum debit and credit===================================
  const sumDebit = journalEntryList?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.debit,
    0
  );

  const sumCredit = journalEntryList?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.credit,
    0
  );
  //================end sum debit and credit===================================

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {t("modal_title_update_journal")}
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              {sumDebit === sumCredit ? null : (
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      border: "1px solid red",
                      padding: "10px",
                      width: "50%",
                      borderRadius: "7px",
                    }}
                  >
                    <Typography variant="body2" color="red">
                      Debit and Credit not equal!
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={6} md={3.5}>
                <Typography className="field-title">
                  {t("thead_transaction_date")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    mask="__-__-____"
                    value={values?.record_date}
                    onChange={(e) => setFieldValue("record_date", e)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography className="field-title">
                  {t("thead_journal_number")}
                </Typography>
                <TextField
                  disabled
                  className="text-field"
                  fullWidth
                  size="small"
                  value={journalNumberFormat(editData?.journal_number)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer sx={{ border: "none" }}>
                  <TableHead sx={{ border: "white" }}>
                    <TableRow className="table-head">
                      <TableCell width="35%" className="thead-title-start">
                        Chart of account
                      </TableCell>
                      <TableCell width="30%" className="thead-title">
                        {t("thead_description")}
                      </TableCell>
                      <TableCell width="15%" className="thead-title">
                        {t("thead_debit")}
                      </TableCell>
                      <TableCell width="15%" className="thead-title">
                        {t("thead_credit")}
                      </TableCell>
                      <TableCell width="5%" className="thead-title-end">
                        <IconButton onClick={handleAddJournalEntryList}>
                          <AddCircleOutlineIcon style={{ color: "green" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    <ListJournalEntry
                      requiredField={requiredField}
                      setUpdateChartAccount={setUpdateChartAccount}
                      setUpdateDescription={setUpdateDescription}
                      setUpdateCredit={setUpdateCredit}
                      setUpdateDebit={setUpdateDebit}
                      journalEntryList={journalEntryList}
                      deleteJournalEntryList={deleteJournalEntryList}
                    />
                  </TableBody>
                </TableContainer>
              </Grid>

              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6} className="total-field">
                <Stack
                  direction="row"
                  spacing={3}
                  display="flex"
                  justifyContent="right"
                >
                  <TextField
                    value={currencyFormat(sumDebit)}
                    size="small"
                    className="total-result"
                  />
                  <TextField
                    value={currencyFormat(sumCredit)}
                    size="small"
                    className="total-result"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">Memo</Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="memo"
                  {...getFieldProps("memo")}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("update")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
