import { gql } from "@apollo/client";

export const CREATE_CHART_OF_ACCOUNT = gql`
  mutation CreateChartOfAccount($input: ChartOfAccountInput) {
    createChartOfAccount(input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_CHART_OF_ACCOUNT = gql`
  mutation UpdateChartOfAccount(
    $chartAccountId: ID!
    $input: ChartOfAccountInput
  ) {
    updateChartOfAccount(chart_account_id: $chartAccountId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_ACCOUNT_TYPE = gql`
  query Query {
    getAccountType
  }
`;

export const GET_BALANCE_BY_CHART_ACCOUNT = gql`
  query GetBalanceByChartAccountId(
    $chartAccountId: ID
    $startDate: String
    $endDate: String
  ) {
    getBalanceByChartAccountId(
      chart_account_id: $chartAccountId
      start_date: $startDate
      end_date: $endDate
    ) {
      _id
      account_name
      account_type
      total_debit
      total_credit
      total_balance
    }
  }
`;

export const GET_CHART_OF_ACCOUNT_WITH_PAGINATION = gql`
  query GetChartOfAccountWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $departmentId: [String]
    $accountType: String
  ) {
    getChartOfAccountWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      department_id: $departmentId
      account_type: $accountType
    ) {
      data {
        account_name
        _id
        account_description
        account_type
        code_account
        createdAt
        is_parents
        is_top_parents
        total_balance
        updatedAt
        department_id {
          _id
          department_name
          description
          createdAt
          updatedAt
        }
        parents_account {
          _id
          account_name
        }
        sub_account {
          _id
          is_parents
          createdAt
          code_account
          account_type
          account_name
          account_description
          total_balance
          expense_type_id {
            _id
            expense_name
          }
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const DELETE_CHART_OF_ACCOUNT = gql`
  mutation DeleteChartOfAccount($chartAccountId: ID!) {
    deleteChartOfAccount(chart_account_id: $chartAccountId) {
      isSuccess
      message
    }
  }
`;

export const GET_LIST_OF_CHARTACCOUNT = gql`
  query GetChartOfAccountList($departmentId: [String], $accountType: [String]) {
    getChartOfAccountList(
      department_id: $departmentId
      account_type: $accountType
    ) {
      _id
      account_type
      account_name
      code_account
      department_id {
        _id
        department_name
        description
        createdAt
        updatedAt
      }
      total_balance
      account_description
      is_parents
      is_top_parents
      parents_account {
        _id
        account_name
      }
      sub_account {
        _id
        account_name
      }
      createdAt
      updatedAt
    }
  }
`;
