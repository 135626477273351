import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// See: https://firebase.google.com/docs/web/learn-more#config-object

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1EPXxkKkt6m2Uqq9wQRX6pUUGXiC6Ry0",
  authDomain: "leap-angkor-accounting.firebaseapp.com",
  projectId: "leap-angkor-accounting",
  storageBucket: "leap-angkor-accounting.appspot.com",
  messagingSenderId: "824586305398",
  appId: "1:824586305398:web:32b665f20484bd7de2c457",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage();
// Initialize Firebase Authentication and get a reference to the service
export default app;
