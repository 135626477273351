import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Stack,
  Button,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
//Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
//Srcs
import "./accounting.scss";
import CompanyLogo from "../Assets/leap-angkor-logo.png";
import { SelectDepartment } from "../Components/Include/DynamicSelect";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import LogoutMenu from "../Components/Menu/LogoutMenu";

export default function FormDialog() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  // ================== Function menu logout ===================
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ================== Function open logout ===================
  const [oepnLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => {
    setOpenLogout(true);
    handleClose();
  };
  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const [selectedVal, setSelectedVal] = useState({
    id: "",
    title: "",
  });

  useEffect(() => {
    if (selectedVal?.id !== "" || selectedVal?.id !== undefined) {
      window.localStorage.setItem("departmentVal", JSON.stringify(selectedVal));
    } else {
      window.localStorage.setItem("departmentVal", JSON.stringify(""));
    }
  }, [selectedVal?.id]);

  const handleNext = () => {
    setLoading(true);
    handleClose();
    navigate("/");
    window.location.reload();
    setLoading(false);
  };

  return (
    <div className="accounting-container">
      <AppBar sx={{ boxShadow: "none" }} position="fixed">
        <Toolbar className="top-bar">
          <Stack
            direction="row"
            spacing={1}
            sx={{ width: "100%", height: "60px" }}
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1}>
              <img alt="Image" src={CompanyLogo} className="company-logo" />
              <Stack direction="column" justifyContent="center">
                <Typography className="company-name">LEAP ANGKOR</Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1.4} justifyContent="right">
              <Stack direction="column" justifyContent="center">
                <Button className="btn-menu" onClick={handleClick}>
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column" justifyContent="center">
                      <Avatar src="" alt="User" className="avatar-user" />
                    </Stack>
                    <Stack direction="column" justifyContent="center">
                      <Typography className="username-text">
                        {userLogin !== undefined && userLogin !== ""
                          ? userLogin?.user_first_name +
                            " " +
                            userLogin?.user_last_name
                          : "User Log"}
                      </Typography>
                    </Stack>
                    <Stack direction="column" justifyContent="center">
                      {openMenu ? (
                        <ArrowDropUpIcon className="icon-menu" />
                      ) : (
                        <ArrowDropDownIcon className="icon-menu" />
                      )}
                    </Stack>
                  </Stack>
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openMenu}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleOpenLogout}>Logout </MenuItem>
                </Menu>

                <LogoutMenu open={oepnLogout} handleClose={handleCloseLogout} />
              </Stack>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <Stack className="body-container" direction="row" justifyContent="center">
        <Stack direction="column" justifyContent="center">
          <Box className="card-container">
            <Stack direction="column" spacing={4}>
              <Stack direction="column">
                <Typography className="text-header">
                  {t("modal_qucik_access")}
                </Typography>
                <Typography className="text-body">
                  {t("modal_accounting_management")}
                </Typography>
              </Stack>
              <SelectDepartment
                selectedVal={selectedVal}
                setSelectedVal={setSelectedVal}
              />
              <Button
                className="btn-next"
                disabled={
                  selectedVal?.id === "" || selectedVal?.id === undefined
                }
                onClick={handleNext}
              >
                {loading ? "Loading..." : t("next")}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
}
