import React, { useContext, useState, useEffect } from "react";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
//Srcs
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { GET_DEPARTMENT_WITH_PAGINATION } from "../../Schema/Depatment";
import {
  ASSIGN_DEPARTMENT_TO_USER,
  DELETE_DEPARTMENT_FROM_USER,
} from "../../Schema/User";

export default function UserAccess({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  // Change Language
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  // console.log("editData::", editData);

  const { refetch: refetchDepartment } = useQuery(
    GET_DEPARTMENT_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
      },
      onCompleted: ({ getDepartmentWithPagination }) => {
        // console.log(
        //   "getDepartmentWithPagination::",
        //   getDepartmentWithPagination
        // );
        setSelectOptions(getDepartmentWithPagination?.data);
      },
      onError: (error) => {
        console.log("error");
      },
    }
  );

  const [assignDepartment] = useMutation(ASSIGN_DEPARTMENT_TO_USER, {
    onCompleted: ({ assignDepartment }) => {
      setLoading(false);
      // console.log("assignDepartment::", assignDepartment);
      if (assignDepartment?.isSuccess === true) {
        setAlert(true, "success", assignDepartment?.message);
        setRefetch();
      } else {
        setAlert(true, "error", assignDepartment?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const [deleteAssignedDepartment] = useMutation(DELETE_DEPARTMENT_FROM_USER, {
    onCompleted: ({ deleteAssignedDepartment }) => {
      setLoading(false);
      if (deleteAssignedDepartment?.isSuccess === true) {
        setAlert(true, "success", deleteAssignedDepartment?.message);
        setRefetch();
      } else {
        setAlert(true, "error", deleteAssignedDepartment?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleAssign = () => {
    setLoading(true);
    assignDepartment({
      variables: {
        userId: editData?._id,
        departmentId: selectedDepartment,
      },
    });
  };

  const handleDeleteAssign = (departmentId) => {
    setLoading(true);
    deleteAssignedDepartment({
      variables: {
        userId: editData?._id,
        departmentId: departmentId,
      },
    });
  };

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {t("modal_title_user_access_department")}
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <Stack direction="column" spacing={1}>
              <Typography className="field-title">
                Department selected
              </Typography>
              {editData?.departments_access?.map((d, index) => {
                return (
                  <Stack direction="row" spacing={2} key={index}>
                    <Typography className="department-title">
                      {d?.department_name}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteAssign(d?._id)}
                    >
                      <Box className="access-delete-container">
                        <MdDelete className="delete-icon" />
                      </Box>
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography className="field-title">Select department</Typography>
            <FormControl fullWidth size="small">
              <Select
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                {selectOptions?.map((d) => {
                  return (
                    <MenuItem key={d?._id} value={d?._id}>
                      {d?.department_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-create" fullWidth>
                {t("loading")}
              </Button>
            ) : (
              <Button className="btn-create" fullWidth onClick={handleAssign}>
                {t("assign")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
