import { useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
//Srcs
import { translateLauguage } from "../../Function/Translate";
import { AuthContext } from "../../Context/AuthContext";
import { GET_CHART_OF_ACCOUNT_WITH_PAGINATION } from "../../Schema/ChartOfAccount";
import { GET_DEPARTMENT_WITH_PAGINATION } from "../../Schema/Depatment";
import { GET_EXPENSE_TYPE_WITH_PAGINATION } from "../../Schema/ExpenseType";

//=========================== select accounts ================================
export function SelectAccount({
  selectedVal,
  setSelectVal,
  departmentId,
  accountType,
  disabled,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkedRefectch, setCheckedRefectch] = useState(false);

  const { data: dataAccount, refetch: refetchAccount } = useQuery(
    GET_CHART_OF_ACCOUNT_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
        accountType: accountType,
        departmentId: departmentId,
      },
      onCompleted: ({ getChartOfAccountWithPagination }) => {
        // console.log("getChartOfAccountWithPagination::", getChartOfAccountWithPagination)
        let rows = [];
        if (getChartOfAccountWithPagination?.data?.length !== 0) {
          getChartOfAccountWithPagination?.data?.map((d) => {
            const allRows = {
              id: d?._id,
              title: d?.account_name,
            };
            rows.push(allRows);
            setSelectOptions(rows);
          });
        } else {
          selectOptions([]);
        }
      },
      onError: (error) => {
        console.error("Error", error?.message);
      },
    }
  );

  const handleSelect = (val) => {
    setSelectVal(val);
    setCheckedRefectch(!checkedRefectch);
  };
  useEffect(() => {
    refetchAccount();
  }, [checkedRefectch]);

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => handleSelect(val)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? t("thead_account_select") : option.title
      }
      renderInput={(param) => (
        <TextField
          {...param}
          size="small"
          placeholder={t("label_select_account")}
        />
      )}
    />
  );
}

//===============================Select Department=================================
export function SelectDepartment({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
  type,
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkedRefectch, setCheckedRefectch] = useState(false);
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  // console.log("userLogin::", userLogin);

  const { refetch: refetchDepartment } = useQuery(
    GET_DEPARTMENT_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        limit: 1000,
        pagination: false,
        userId: userLogin?._id,
        // userId: "64acc01d5d33bd0feffdd342",
      },
      onCompleted: ({ getDepartmentWithPagination }) => {
        // console.log(
        //   "getDepartmentWithPagination::",
        //   getDepartmentWithPagination
        // );
        const rows = [];
        if (getDepartmentWithPagination?.data?.length > 0) {
          getDepartmentWithPagination?.data?.map((val) => {
            const row = {
              id: val._id,
              title: val.department_name,
            };
            rows.push(row);
            setSelectOptions(rows);
          });
        } else {
          setSelectOptions([]);
        }
      },
      onError: (error) => {
        console.log("error=>", error);
      },
    }
  );

  const handleSelect = (val, key) => {
    setSelectedVal(val);
    setCheckedRefectch(!checkedRefectch);
    if (type === "TopNavbar") {
      window.location.reload();
    }
  };

  useEffect(() => {
    refetchDepartment();
  }, [checkedRefectch, userLogin?._id]);

  return (
    <Autocomplete
      disabled={disabled}
      disablePortal
      className={className}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => handleSelect(val)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select department" : option.title
      }
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select department" />
      )}
    />
  );
}

//=============================== Select Expense type =================================
export function SelectExpenseType({ selectedVal, setSelectedVal, disabled }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkedRefectch, setCheckedRefectch] = useState(false);

  const { refetch: refetchDepartment } = useQuery(
    GET_EXPENSE_TYPE_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
      },
      onCompleted: ({ getExpenseTypePagination }) => {
        // console.log("getExpenseTypePagination::", getExpenseTypePagination);
        const rows = [];
        if (getExpenseTypePagination?.data?.length > 0) {
          getExpenseTypePagination?.data?.map((val) => {
            const row = {
              id: val._id,
              title: val.expense_name,
            };
            rows.push(row);
            setSelectOptions(rows);
          });
        } else {
          setSelectOptions([]);
        }
      },
      onError: (error) => {
        console.log("error");
      },
    }
  );

  const handleSelect = (val, key) => {
    setSelectedVal(val);
    setCheckedRefectch(!checkedRefectch);
  };

  useEffect(() => {
    refetchDepartment();
  }, [checkedRefectch]);

  return (
    <Autocomplete
      disabled={disabled}
      disablePortal
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => handleSelect(val)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select expense type" : option.title
      }
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select expense type" />
      )}
    />
  );
}
