import React, { useContext, useEffect, useState, useMemo } from "react";
import "./App.css";
import Router from "./routes";
import { BrowserRouter as Routers } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AlertMessage from "./Components/AlertMessage/AlertMessage";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { AuthContext } from "./Context/AuthContext";

function App() {
  const { state } = useContext(AuthContext);
  const { user } = state;
  // ====================== set token =======================
  const [token, setToken] = useState( JSON.parse(window.localStorage.getItem("token")));

  useEffect(() => {
    let userToken = JSON.parse(window.localStorage.getItem("token"));
    if (userToken) {
      setToken(userToken);
      return;
    }

    if (user?.token) {
      setToken(user?.token);
      window.localStorage.setItem("token", JSON.stringify(user?.token));
    } else {
      setToken("");
    }
  }, [user]);

  //================ CREATE THEME ====================
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: "light",
          background: {
            default: "#EBEDEF",
          },
        },
        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),
    []
  );

  //================= Connect Apollo Client ======================
  const httpLink = createHttpLink({
    // uri: "http://192.168.2.18:4400/graphql",
    uri: process.env.REACT_APP_END_POINT,
  });

  const authLink = setContext((_, { headers }) => {
    // console.log("headers::", headers, token);
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    // link: ApolloLink.from([logoutLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Routers>
          <Router />
        </Routers>
        <CssBaseline />
      </ThemeProvider>
      <AlertMessage />
    </ApolloProvider>
  );
}

export default App;
