import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { MdModeEdit, MdDelete, MdAssignmentReturned } from "react-icons/md";
import { BsFillShieldLockFill } from "react-icons/bs";
//Srcs
import "../../Style/actionstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import DeleteUser from "./DeleteUser";
import UpdateUser from "./UpdateUser";
import UserAccess from "./UserAccess";
import ResetPassword from "./ResetPassword";

export default function UserAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  //Modal open access
  const [openAccess, setOpenAccess] = useState(false);
  const handleOpenAccess = () => setOpenAccess(true);
  const handleCloseAccess = () => setOpenAccess(false);

  //Modal open reset
  const [openReset, setOpenReset] = useState(false);
  const handleOpenReset = () => setOpenReset(true);
  const handleCloseReset = () => setOpenReset(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        {editData?.role === "Super Admin" ? null : (
          <Tooltip title="Assign department" placement="top">
            <IconButton onClick={handleOpenAccess}>
              <Box className="assign-container">
                <MdAssignmentReturned className="assign-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        )}
        {editData?.role === "Super Admin" ? (
          <Tooltip title="Reset password" placement="top">
            <IconButton onClick={handleOpenReset}>
              <Box className="reset-container">
                <BsFillShieldLockFill className="reset-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title={t("modal_title_update_user")} placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <MdModeEdit className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title={t("modal_title_delete_user")} placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <ResetPassword
        open={openReset}
        handleClose={handleCloseReset}
        editData={editData}
        setRefetch={setRefetch}
      />

      <UserAccess
        open={openAccess}
        handleClose={handleCloseAccess}
        editData={editData}
        setRefetch={setRefetch}
      />

      <UpdateUser
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
      />

      <DeleteUser
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
