import React, { useContext, useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { string, ref } from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import CropImageFile from "../CropImage/CropImageFile";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { CREATE_USER } from "../../Schema/User";

export default function CreateUser({ open, handleClose, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  //hiden and show vibility
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser }) => {
      setLoading(false);
      // console.log("createUser::", createUser);
      if (createUser?.isSuccess === true) {
        setAlert(true, "success", createUser?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createUser?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const AddUser = Yup.object().shape({
    user_first_name: Yup.string().required("require!"),
    user_last_name: Yup.string().required("require!"),
    password: Yup.string()
      .required("required!")
      .min(8, "Please input more then 8 charactor!"),
    confirmPassword: string()
      .required("require!")
      .oneOf([ref("password")], "Wrong password!"),
    user_email: Yup.string().email("Invalid email!").required("require!"),
    role: Yup.string(),
    status: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      user_first_name: "",
      user_last_name: "",
      password: "",
      user_email: "",
      role: "Admin",
      confirmPassword: "",
      status: true,
    },

    validationSchema: AddUser,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      createUser({
        variables: {
          input: {
            user_first_name: values?.user_first_name,
            user_last_name: values?.user_last_name,
            user_email: values?.user_email,
            password: values?.password,
            role: values?.role,
            status: values?.status,
            user_image_name: imageFile ? "User profile" : " ",
            user_image_src: imageFile ? profileHook : "",
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="dialog-container" maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {t("modal_title_add_user")}
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box>
                    <Stack direction="row" justifyContent="center">
                      <Tooltip title="click to upload">
                        <IconButton component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <Avatar
                            className="avater-image"
                            src={
                              imageFile ? URL.createObjectURL(imageFile) : null
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_firstname")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("label_firstname")}
                  {...getFieldProps("user_first_name")}
                  error={Boolean(
                    touched.user_first_name && errors.user_first_name
                  )}
                  helperText={touched.user_first_name && errors.user_first_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_lastname")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("label_lastname")}
                  {...getFieldProps("user_last_name")}
                  error={Boolean(
                    touched.user_last_name && errors.user_last_name
                  )}
                  helperText={touched.user_last_name && errors.user_last_name}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_email")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("label_email")}
                  {...getFieldProps("user_email")}
                  error={Boolean(touched.user_email && errors.user_email)}
                  helperText={touched.user_email && errors.user_email}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_role")}
                </Typography>
                <FormControl className="text-field" fullWidth size="small">
                  <Select
                    value={values?.role}
                    onChange={(e) => setFieldValue("role", e.target.value)}
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Super Admin">Super Admin</MenuItem>
                    <MenuItem value="Reader">Reader</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_password")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  type={show ? "text" : "password"}
                  placeholder={t("label_password")}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon className="viewpw-icon" />
                        ) : (
                          <VisibilityOffIcon className="viewpw-icon" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_confirmpassword")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type={show ? "text" : "password"}
                  placeholder={t("label_confirmpassword")}
                  {...getFieldProps("confirmPassword")}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
