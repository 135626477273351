import React, { useContext, useState } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//Srcs
import "./setting.scss";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import UserIcon from "../Assets/icon_setting/profile.png";
import DepartmentIcon from "../Assets/icon_setting/department.png";
import ExpenseType from "../Assets/icon_setting/spending-money.png";

export default function Setting() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(true);

  return (
    <div className="setting-page">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">{t("page_setting")}</Typography>
          <Typography className="page-subtitle">
            Edit abilities of system can do.
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={0.6}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/">
              {t("page_dashboard")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">
              {t("page_setting")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ mt: "30px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <Link to="/setting/users" style={{ textDecoration: "none" }}>
              <Box className="setting-card-container">
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column" justifyContent="center">
                      <img src={UserIcon} className="image" />
                    </Stack>
                    <Stack direction="column">
                      <Typography className="page-title">
                        {t("thead_user")}
                      </Typography>
                      <Typography className="page-description">
                        {t("thead_create_update_delete_user")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <Link to="/setting/department" style={{ textDecoration: "none" }}>
              <Box className="setting-card-container">
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column" justifyContent="center">
                      <img src={DepartmentIcon} className="image" />
                    </Stack>
                    <Stack direction="column">
                      <Typography className="page-title">
                        {t("thead_department")}
                      </Typography>
                      <Typography className="page-description">
                        {t("thead_create_update_head_of_chart")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <Link to="/setting/expense-type" style={{ textDecoration: "none" }}>
              <Box className="setting-card-container">
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column" justifyContent="center">
                      <img src={ExpenseType} className="image" />
                    </Stack>
                    <Stack direction="column">
                      <Typography className="page-title">
                        Expense type
                      </Typography>
                      <Typography className="page-description">
                        create and update expense
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
