import React, { useState, useContext, useEffect } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Srcs
import "./dashboard.scss";
import { currencyFormat } from "../../Function/DynamicFn";
import ProfitIcon from "../../Assets/profit.png";
import { GET_SUMMARY_INCOME_STATEMENT } from "../../Schema/Dashboard";

export default function BlockHeader({ startDate, endDate, departmentId }) {
  const [summaryIncomeData, setSummaryIncomeData] = useState({});
  const { refetch } = useQuery(GET_SUMMARY_INCOME_STATEMENT, {
    variables: {
      departmentId: departmentId,
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ getSummaryIncomeStatment }) => {
      setSummaryIncomeData(getSummaryIncomeStatment);
      // console.log("getSummaryIncomeStatment::", getSummaryIncomeStatment);
    },
    onError: (error) => {
      console.log(error?.message);
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate, departmentId]);

  return (
    <div className="dashboard-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            className="card-container"
            direction="column"
            justifyContent="center"
          >
            <Stack direction="row" spacing={3}>
              <img src={ProfitIcon} alt="" className="icon-money" />
              <Stack direction="column" className="each-data">
                <Typography className="all-sale">Revenue</Typography>
                <Typography className="amount-number">
                  {currencyFormat(
                    summaryIncomeData?.revenue ? summaryIncomeData?.revenue : 0
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            className="card-container"
            direction="column"
            justifyContent="center"
          >
            <Stack direction="row" spacing={3}>
              <img src={ProfitIcon} alt="" className="icon-money" />
              <Stack direction="column" className="each-data">
                <Typography className="all-sale">Total cost</Typography>
                <Typography className="amount-number">
                  {currencyFormat(
                    summaryIncomeData?.costOfSale
                      ? summaryIncomeData?.costOfSale
                      : 0
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            className="card-container"
            direction="column"
            justifyContent="center"
          >
            <Stack direction="row" spacing={3}>
              <img src={ProfitIcon} alt="" className="icon-money" />
              <Stack direction="column" className="each-data">
                <Typography className="all-sale">Gross Profit</Typography>
                <Typography className="amount-number">
                  {currencyFormat(
                    summaryIncomeData?.grossProfit
                      ? summaryIncomeData?.grossProfit
                      : 0
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            className="card-container"
            direction="column"
            justifyContent="center"
          >
            <Stack direction="row" spacing={3}>
              <img src={ProfitIcon} alt="" className="icon-money" />
              <Stack direction="column" className="each-data">
                <Typography className="all-sale">Expense</Typography>
                <Typography className="amount-number">
                  {currencyFormat(
                    summaryIncomeData?.Expense ? summaryIncomeData?.Expense : 0
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            className="card-container"
            direction="column"
            justifyContent="center"
          >
            <Stack direction="row" spacing={3}>
              <img src={ProfitIcon} alt="" className="icon-money" />
              <Stack direction="column" className="each-data">
                <Typography className="all-sale">Net Income</Typography>
                <Typography className="amount-number">
                  {currencyFormat(
                    summaryIncomeData?.netIncome
                      ? summaryIncomeData?.netIncome
                      : 0
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
