import { gql } from "@apollo/client";

export const CREATE_JOURNAL = gql`
  mutation CreateJournal($input: GeneralJournalInput) {
    createJournal(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_LAST_JOURNAL_NUMBER = gql`
  query Query {
    getLastJournalNumber
  }
`;

export const GET_JOURNAL_WITH_PAGINATION = gql`
  query GetJournalWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $fromDate: String
    $toDate: String
  ) {
    getJournalWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      fromDate: $fromDate
      toDate: $toDate
    ) {
      data {
        _id
        transaction_title
        currency
        isClosedRepord
        record_date
        journal_number
        journal_entries {
          chart_account_id {
            _id
            account_name
            is_parents
            parents_account {
              _id
              account_name
            }
          }
          credit
          debit
          description
          key
        }
        created_by {
          _id
          user_first_name
          user_last_name
          user_email
          user_image_name
          user_image_src
          role
          status
          createdAt
          updatedAt
        }
        memo
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_JOURNAL = gql`
  mutation UpdateJournal($journalId: ID!, $input: GeneralJournalInput) {
    updateJournal(journal_id: $journalId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_JOURNAL = gql`
  mutation DeleteJournal($journalId: ID!) {
    deleteJournal(journal_id: $journalId) {
      isSuccess
      message
    }
  }
`;

export const GET_JOURNAL_BY_ID = gql`
  query GetJournalById($journalId: ID!) {
    getJournalById(journal_id: $journalId) {
      _id
      transaction_title
      currency
      record_date
      journal_number
      journal_entries {
        chart_account_id {
          _id
          account_name
          account_type
          is_parents
          parents_account {
            _id
            account_name
          }
        }
        credit
        debit
        description
        key
      }
      created_by {
        _id
        user_first_name
        user_last_name
        user_email
        user_image_name
        user_image_src
        role
        status
        createdAt
        updatedAt
      }
      memo
      createdAt
      updatedAt
    }
  }
`;
