import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
//srcs
import EmptyData from "../Include/EmptyData";
import LoadingPage from "../Include/LoadingPage";
import { INCOME_STATEMENT_REPORT } from "../../Schema/Report";
import { currencyFormat } from "../../Function/DynamicFn";

export default function IncomeStatement({
  startDate,
  endDate,
  departmentId,
  formIncomeStatement,
}) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(INCOME_STATEMENT_REPORT, {
    variables: {
      departmentId: departmentId,
      form:
        departmentId === "64a52c65ad409eb75c87d8e1"
          ? formIncomeStatement
          : null,
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ incomeStatementReport }) => {
      // console.log("incomeStatementReport::", incomeStatementReport);
      setTableData(incomeStatementReport);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [departmentId, formIncomeStatement, startDate, endDate]);

  return (
    <table className="report-container">
      <tr>
        <th className="thead-account-statement">Account</th>
        <th className="thead-current-month">Current month</th>
        <th className="thead-year-toyear">Year to date</th>
      </tr>
      {loading ? (
        <tr>
          <td colSpan={6}>
            <LoadingPage />
          </td>
        </tr>
      ) : (
        <Fragment>
          {tableData?.revenues?.length === 0 &&
          tableData?.costOfSales?.length === 0 &&
          tableData?.expenses?.length === 0 ? (
            <tr>
              <td colSpan={6} className="report-body-cell">
                <EmptyData />
              </td>
            </tr>
          ) : (
            <>
              {/* ======================================= Revenues =========================================== */}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                  colSpan={4}
                >
                  REVENUES
                </td>
              </tr>
              {tableData?.revenues?.map((row, index) => {
                return (
                  <tr key={index} className="report-body-row">
                    <td
                      className="report-body-cell-statement-sub1"
                      style={{ padding: "8px 0px 8px 20px" }}
                    >
                      {row?.account_name}
                    </td>
                    <td className="report-body-cell">
                      {currencyFormat(row?.selectedDateBalance)}
                    </td>
                    <td className="report-body-cell">
                      {currencyFormat(row?.yearToDateBalance)}
                    </td>
                  </tr>
                );
              })}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                >
                  TOTAL REVENUES
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.totalRevenue?.selectedDateBalance)}
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.totalRevenue?.yearToDateBalance)}
                </td>
              </tr>

              {/* ======================================= Cost of sales =========================================== */}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                  colSpan={4}
                >
                  COST OF SALES
                </td>
              </tr>
              {tableData?.costOfSales?.map((row, index) => {
                return (
                  <tr key={index} className="report-body-row">
                    <td
                      className="report-body-cell-statement-sub1"
                      style={{ padding: "8px 0px 8px 20px" }}
                    >
                      {row?.account_name}
                    </td>
                    <td className="report-body-cell">
                      {currencyFormat(row?.selectedDateBalance)}
                    </td>
                    <td className="report-body-cell">
                      {currencyFormat(row?.yearToDateBalance)}
                    </td>
                  </tr>
                );
              })}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                >
                  TOTAL COST OF SALES
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.totalCost?.selectedDateBalance)}
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.totalCost?.yearToDateBalance)}
                </td>
              </tr>

              {/* ======================================= Total gross profit =========================================== */}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                >
                  GROSS PROFIT
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.grossProfit?.selectedDateBalance)}
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.grossProfit?.yearToDateBalance)}
                </td>
              </tr>
              {/* ======================================= Expense =========================================== */}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                  colSpan={4}
                >
                  EXPENSE
                </td>
              </tr>
              {tableData?.expenses?.map((row, index) => {
                return (
                  <tr key={index} className="report-body-row">
                    <td
                      className="report-body-cell-statement-sub1"
                      style={{ padding: "8px 0px 8px 20px" }}
                    >
                      {row?.account_name}
                    </td>
                    <td className="report-body-cell">
                      {currencyFormat(row?.selectedDateBalance)}
                    </td>
                    <td className="report-body-cell">
                      {currencyFormat(row?.yearToDateBalance)}
                    </td>
                  </tr>
                );
              })}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                >
                  TOTAL EXPENSE
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.totalExpense?.selectedDateBalance)}
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.totalExpense?.yearToDateBalance)}
                </td>
              </tr>
              {/* ======================================= Total net income ================================= */}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                >
                  NET INCOME
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.netIncome?.selectedDateBalance)}
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.netIncome?.yearToDateBalance)}
                </td>
              </tr>
            </>
          )}
        </Fragment>
      )}
    </table>
  );
}
