import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Stack, Typography, Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Srcs
import "./dashboard.scss";
import LoadingPage from "../Include/LoadingPage";
import EmptyData from "../Include/EmptyData";
import { currencyFormat } from "../../Function/DynamicFn";
import { GET_REVENUE_BY_DEPARTMENT } from "../../Schema/Dashboard";

export default function RevenueChart({ startDate, endDate, departmentId }) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const { refetch } = useQuery(GET_REVENUE_BY_DEPARTMENT, {
    variables: {
      departmentId: departmentId,
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ getRevenueByDepartment }) => {
      setChartData(getRevenueByDepartment);
      setLoading(false);
      // console.log("getRevenueByDepartment::", getRevenueByDepartment);
    },
    onError: (error) => {
      console.log(error?.message);
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate, departmentId]);

  const state = {
    series: [
      {
        data: chartData?.map(({ balance }) => balance),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
        // formatter: function (val) {
        //   return currencyFormat(val);
        // },
      },
      xaxis: {
        categories: chartData?.map(({ account_name }) => account_name),
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return currencyFormat(val);
          },
        },
      },
    },
  };

  return (
    <div id="chart" className="dashboard-container">
      <Stack direction="column" spacing={1} className="account-chart-container">
        <Typography className="chart-title">Revenue</Typography>
        {loading ? (
          <Box className="account-chart">
            <LoadingPage />
          </Box>
        ) : (
          <>
            {chartData?.length === 0 ? (
              <Box className="account-chart">
                <EmptyData />
              </Box>
            ) : (
              <Box className="account-chart">
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type="bar"
                  height={250}
                />
              </Box>
            )}
          </>
        )}
      </Stack>
    </div>
  );
}
