import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
//srcs
import EmptyData from "../Include/EmptyData";
import LoadingPage from "../Include/LoadingPage";
import { GET_BALANCE_SHEET_REPORT } from "../../Schema/Report";
import { currencyFormat } from "../../Function/DynamicFn";

export default function BalanceSheet({ startDate, endDate }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  console.log(startDate, "startDate");
  console.log(endDate, "endDate");
  const { refetch } = useQuery(GET_BALANCE_SHEET_REPORT, {
    variables: {
      fromDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
      toDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
    },
    onCompleted: ({ balanceSheetReport }) => {
      console.log("balanceSheetReport::", balanceSheetReport);
      setTableData(balanceSheetReport);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  return (
    <table className="report-container">
      {/* <thead> */}
      <tr>
        <th className="thead-account">Account</th>
        <th className="thead-month-balancesheet">Balance</th>
        {/* <th className="thead-month-balancesheet">Last month</th> */}
      </tr>
      {/* </thead> */}
      {loading ? (
        // <tbody>
        <tr>
          <td colSpan={3}>
            <LoadingPage />
          </td>
        </tr>
      ) : (
        // </tbody>
        <Fragment>
          {tableData?.asset?.length === 0 && tableData?.equity?.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <EmptyData />
              </td>
            </tr>
          ) : (
            <>
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                  colSpan={3}
                >
                  ASSETS
                </td>
              </tr>
              {tableData?.asset?.map((row, index) => {
                return (
                  <>
                    <tr className="report-body-row" key={row?.account_name}>
                      <td
                        className="report-body-cell-sub1"
                        style={{
                          padding: "8px 0px 8px 20px",
                          fontWeight: "bold",
                        }}
                        colSpan={3}
                      >
                        {row?.account_name}
                      </td>
                    </tr>
                    {row?.sub_account?.length !== 0 ? (
                      <>
                        {row?.sub_account?.map((sub, index1) => {
                          return (
                            <tr key={index1} className="report-body-row">
                              <td
                                className="report-body-cell-sub2"
                                style={{ padding: "8px 0px 8px 40px" }}
                              >
                                {sub?.account_name}
                              </td>
                              <td className="report-body-cell">
                                {currencyFormat(
                                  sub?.total_balance?.current_month_balance
                                )}
                              </td>
                              {/* <td className="report-body-cell">
                                {currencyFormat(
                                  sub?.total_balance?.last_month_balance
                                )}
                              </td> */}
                            </tr>
                          );
                        })}
                      </>
                    ) : null}
                    <tr
                      className="report-body-row"
                      key={index + "" + row?.account_name}
                    >
                      <td
                        className="report-body-cell-sub1"
                        style={{
                          padding: "8px 0px 8px 20px",
                          fontWeight: "bold",
                        }}
                      >
                        Total {row?.account_name}
                      </td>
                      <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(
                          row?.total_balance?.current_month_balance
                        )}
                      </td>
                      {/* <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(row?.total_balance?.last_month_balance)}
                      </td> */}
                    </tr>
                  </>
                );
              })}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                >
                  TOTAL ASSETS
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(
                    tableData?.total_asset?.current_month_balance
                  )}
                </td>
                {/* <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(tableData?.total_asset?.last_month_balance)}
                </td> */}
              </tr>

              {/* ======================================= Liabilities and capital =========================================== */}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                  colSpan={3}
                >
                  LIABILITIES AND CAPITAL
                </td>
              </tr>
              {tableData?.liability?.map((row, index) => {
                return (
                  <>
                    <tr className="report-body-row" key={row?.account_name}>
                      <td
                        className="report-body-cell-sub1"
                        style={{
                          padding: "8px 0px 8px 20px",
                          fontWeight: "bold",
                        }}
                        colSpan={3}
                      >
                        {row?.account_name}
                      </td>
                    </tr>
                    {row?.sub_account?.length !== 0 ? (
                      <>
                        {row?.sub_account?.map((sub, index1) => {
                          return (
                            <tr className="report-body-row" key={index1}>
                              <td
                                className="report-body-cell-sub2"
                                style={{ padding: "8px 0px 8px 40px" }}
                              >
                                {sub?.account_name}
                              </td>
                              <td className="report-body-cell">
                                {currencyFormat(
                                  sub?.total_balance?.current_month_balance
                                )}
                              </td>
                              {/* <td className="report-body-cell">
                                {currencyFormat(
                                  sub?.total_balance?.last_month_balance
                                )}
                              </td> */}
                            </tr>
                          );
                        })}
                      </>
                    ) : null}
                    <tr
                      className="report-body-row"
                      key={index + "" + row?.account_name}
                    >
                      <td
                        className="report-body-cell-sub1"
                        style={{
                          padding: "8px 0px 8px 20px",
                          fontWeight: "bold",
                        }}
                      >
                        Total {row?.account_name}
                      </td>
                      <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(
                          row?.total_balance?.current_month_balance
                        )}
                      </td>
                      {/* <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(row?.total_balance?.last_month_balance)}
                      </td> */}
                    </tr>
                  </>
                );
              })}

              {tableData?.equity?.map((row, index) => {
                return (
                  <>
                    <tr className="report-body-row" key={row?.account_name}>
                      <td
                        className="report-body-cell-sub1"
                        style={{
                          padding: "8px 0px 8px 20px",
                          fontWeight: "bold",
                        }}
                        colSpan={3}
                      >
                        {row?.account_name}
                      </td>
                    </tr>
                    {row?.sub_account?.length !== 0 ? (
                      <>
                        {row?.sub_account?.map((sub, index1) => {
                          return (
                            <tr className="report-body-row" key={index1}>
                              <td
                                className="report-body-cell-sub2"
                                style={{ padding: "8px 0px 8px 40px" }}
                              >
                                {sub?.account_name}
                              </td>
                              <td className="report-body-cell">
                                {currencyFormat(
                                  sub?.total_balance?.current_month_balance
                                )}
                              </td>
                              {/* <td className="report-body-cell">
                                {currencyFormat(
                                  sub?.total_balance?.last_month_balance
                                )}
                              </td> */}
                            </tr>
                          );
                        })}
                      </>
                    ) : null}
                    <tr
                      className="report-body-row"
                      key={index + "" + row?.account_name}
                    >
                      <td
                        className="report-body-cell-sub1"
                        style={{
                          padding: "8px 0px 8px 20px",
                          fontWeight: "bold",
                        }}
                      >
                        Total {row?.account_name}
                      </td>
                      <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(
                          row?.total_balance?.current_month_balance
                        )}
                      </td>
                      {/* <td
                        className="report-body-cell-total"
                        style={{ fontWeight: "bold" }}
                      >
                        {currencyFormat(row?.total_balance?.last_month_balance)}
                      </td> */}
                    </tr>
                  </>
                );
              })}

              {/* ======================================= Total liability and equity ================================= */}
              <tr className="report-body-row">
                <td
                  className="report-body-cell-header"
                  style={{ fontWeight: "bold" }}
                >
                  TOTAL LIABILITIES AND CAPITAL
                </td>
                <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(
                    tableData?.total_liability_and_equity?.current_month_balance
                  )}
                </td>
                {/* <td
                  className="report-body-cell-total"
                  style={{ fontWeight: "bold" }}
                >
                  {currencyFormat(
                    tableData?.total_liability_and_equity?.last_month_balance
                  )}
                </td> */}
              </tr>
            </>
          )}
        </Fragment>
      )}
    </table>
  );
}
