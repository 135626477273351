import { gql } from "@apollo/client";

export const CREATE_EXPENSE_TYPE = gql`
  mutation CreateExpenseType($input: ExpenseTypeInput) {
    createExpenseType(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_EXPENSE_TYPE_WITH_PAGINATION = gql`
  query GetExpenseTypePagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getExpenseTypePagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        expense_name
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_EXPENSE_TYPE = gql`
  mutation UpdateExpenseType($expenseTypeId: ID!, $input: ExpenseTypeInput) {
    updateExpenseType(expense_type_id: $expenseTypeId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_EXPENSE_TYPE = gql`
  mutation DeleteExpenseType($expenseTypeId: ID!) {
    deleteExpenseType(expense_type_id: $expenseTypeId) {
      isSuccess
      message
    }
  }
`;
