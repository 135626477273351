import { TelegramClient } from "messaging-api-telegram";

//  ================================= For test ================================
export const client = new TelegramClient({
  accessToken: "6017570441:AAH97g8HOGxroGZA8K7828hNS5-pNQSkF84",
  onRequest: ({ method, url, headers, body }) => {
    headers["Content-Type"] = "multipart/form-data";
  },
});

// ============================= For deployment ==================================
// export const client = new TelegramClient({
//   accessToken: "6118530986:AAEwQ3Qt3KFnHEpQiPkPH6kuepHvSDIQy0Q",
//   onRequest: ({ method, url, headers, body }) => {
//     headers["Content-Type"] = "multipart/form-data";
//   },
// });

export const sendMessage = async ({ content }) => {
  try {
    //For deployment
    // const response = await client.sendMessage(-1001395476527, content, {
    //   parse_mode: "HTML",
    //   allow_sending_without_reply: true,
    // });

    const response = await client.sendMessage(-1001814182076, content, {
      parse_mode: "HTML",
      allow_sending_without_reply: true,
    });

    // console.log("response:", response);
  } catch (error) {
    console.log("error:", error);
  }
};
