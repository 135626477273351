import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { MdModeEdit, MdDelete } from "react-icons/md";
//Srcs
import "../../Style/actionstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import CreateExpenseType from "./CreateExpenseType";
import DeleteExpenseType from "./DeleteExpenseType";

export default function DepartmentAction({ setRefetch, editData }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal update
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="Update" placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <MdModeEdit className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete" placement="top">
          <IconButton onClick={handleOpenDelete}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>
      <CreateExpenseType
        open={openEdit}
        handleClose={handleCloseEdit}
        setRefetch={setRefetch}
        editData={editData}
        dialogTitle="Update"
      />
      <DeleteExpenseType
        open={openDelete}
        handleClose={handleCloseDelete}
        setRefetch={setRefetch}
        editData={editData}
      />
    </div>
  );
}
