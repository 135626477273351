import React, { useState, useContext } from "react";
import { IconButton, Stack, Box } from "@mui/material";
//Icons
import { MdModeEdit } from "react-icons/md";
//Srcs
import "../../Style/actionstyle.scss";
import UpdateGeneralJournal from "./UpdateGeneralJournal";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

export default function ViewGeneralJournalAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <IconButton onClick={handleOpenEdit}>
          <Box className="update-container">
            <MdModeEdit className="update-icon" />
          </Box>
        </IconButton>
        <UpdateGeneralJournal
          open={openEdit}
          handleClose={handleCloseEdit}
          editData={editData}
          setRefetch={setRefetch}
        />
      </Stack>
    </div>
  );
}
