import React, { useContext, useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  Checkbox,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { SelectAccount } from "../Include/DynamicSelect";
import {
  CREATE_CHART_OF_ACCOUNT,
  GET_ACCOUNT_TYPE,
} from "../../Schema/ChartOfAccount";
import { SelectDepartment, SelectExpenseType } from "../Include/DynamicSelect";

export default function CreateChartOfAccount({
  open,
  handleClose,
  setRefetch,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [parentsAccountVal, setParentsAccountVal] = useState({
    id: "",
    title: "",
  });
  const [departmentVal, setDepartmentVal] = useState(
    JSON.parse(window.localStorage.getItem("departmentVal"))
  );
  const [expenseTypeVal, setExpenseTypeVal] = useState({
    id: "",
    title: "",
  });
  // ========================== checked box =================================
  const [checked, setChecked] = useState(false);
  const hanldleChange = (event) => {
    setChecked(event.target.checked);
  };

  // ========================== get account type =================================
  const [accountTypeData, setAccountTypeData] = useState([]);
  const { refetch: refetchAccountType } = useQuery(GET_ACCOUNT_TYPE, {
    onCompleted: ({ getAccountType }) => {
      // console.log("getAccountType::", getAccountType);
      setAccountTypeData(getAccountType);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [createChartOfAccount] = useMutation(CREATE_CHART_OF_ACCOUNT, {
    onCompleted: ({ createChartOfAccount }) => {
      setLoading(false);
      if (createChartOfAccount?.isSuccess === true) {
        setAlert(true, "success", createChartOfAccount?.message);
        handleClose();
        resetForm();
        setRefetch();
        setParentsAccountVal({ id: "", title: "" });
        setFieldValue("account_type", "");
      } else {
        setAlert(true, "error", createChartOfAccount?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });
  // console.log("accountType::", accountType)
  const CheckValidation = Yup.object().shape({
    account_type: Yup.string().required("require!"),
    account_name: Yup.string().required("require!"),
    code_account: Yup.string(),
    department_id: Yup.string().required("require!"),
    parents_account:
      checked === true ? Yup.string().required("require!") : Yup.string(),
    account_description: Yup.string(),
    expense_type_id:
      accountType === "Expenditures"
        ? Yup.string().required("require!")
        : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      account_type: "",
      account_name: "",
      code_account: "",
      department_id: "",
      parents_account: "",
      account_description: "",
      expense_type_id: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values::", values);
      setLoading(true);
      createChartOfAccount({
        variables: {
          input: {
            ...values,
            expense_type_id:
              values?.expense_type_id === "" ? null : values?.expense_type_id,
            parents_account:
              values?.parents_account === "" ? null : values?.parents_account,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    setFieldValue("department_id", departmentVal?.id);
  }, [open]);

  useEffect(() => {
    if (departmentVal?.id !== "" || departmentVal?.id !== undefined) {
      setFieldValue("department_id", departmentVal?.id);
    }
  }, [departmentVal?.id]);

  useEffect(() => {
    if (parentsAccountVal?.id !== "" || parentsAccountVal?.id !== undefined) {
      setFieldValue("parents_account", parentsAccountVal?.id);
    }
  }, [parentsAccountVal?.id]);

  useEffect(() => {
    if (checked === false) {
      setFieldValue("parents_account", "");
      setParentsAccountVal({
        id: "",
        title: "",
      });
    }
  }, [checked]);

  useEffect(() => {
    if (values?.account_type) {
      setAccountType(values?.account_type);
    }
  }, [values?.account_type]);

  useEffect(() => {
    if (expenseTypeVal?.id) {
      setFieldValue("expense_type_id", expenseTypeVal?.id);
      setFieldValue("account_name", expenseTypeVal?.title);
    } else {
      setFieldValue("expense_type_id", "");
      setFieldValue("account_name", "");
    }
  }, [expenseTypeVal?.id]);

  useEffect(() => {
    if (expenseTypeVal?.title && departmentVal?.title) {
      setFieldValue("account_name", expenseTypeVal?.title);
    }
  }, [expenseTypeVal?.title, departmentVal?.title]);

  // console.log("vlaues", values)

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {t("modal_title_add_account")}
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">Department</Typography>
                <SelectDepartment
                  selectedVal={departmentVal}
                  setSelectedVal={setDepartmentVal}
                />
                {!!errors.department_id && touched.department_id && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.department_id}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">
                  {t("thead_account_type")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    value={values?.account_type}
                    onChange={(e) =>
                      setFieldValue("account_type", e.target.value)
                    }
                    {...getFieldProps("account_type")}
                    error={Boolean(touched.account_type && errors.account_type)}
                    helperText={touched.account_type && errors.account_type}
                  >
                    {accountTypeData?.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display:
                    values?.account_type === "Expenditures" ? "block" : "none",
                }}
              >
                <Typography className="field-title">Expense type</Typography>
                <SelectExpenseType
                  selectedVal={expenseTypeVal}
                  setSelectedVal={setExpenseTypeVal}
                />
                {!!errors.expense_type_id && touched.expense_type_id && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.expense_type_id}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography className="field-title">
                  {t("thead_code")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("label_code")}
                  {...getFieldProps("code_account")}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography className="field-title">
                  {t("thead_account_name")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("label_account_name")}
                  {...getFieldProps("account_name")}
                  error={Boolean(touched.account_name && errors.account_name)}
                  helperText={touched.account_name && errors.account_name}
                />
              </Grid>

              <Grid item xs={0.5} md={2} sx={{ marginTop: "15px" }}>
                <Checkbox
                  checked={checked}
                  onChange={hanldleChange}
                  iinputProps={{ "aria-label": "controlled" }}
                />
              </Grid>

              <Grid item xs={11.5} md={10}>
                <Typography className="field-title">
                  {t("thead_sub_of_account")}
                </Typography>
                <SelectAccount
                  selectedVal={parentsAccountVal}
                  setSelectVal={setParentsAccountVal}
                  accountType={values?.account_type}
                  departmentId={departmentVal?.id}
                  disabled={
                    checked === false || values?.account_type === ""
                      ? true
                      : false
                  }
                />
                {(checked && values?.parents_account === "") ||
                (checked && values?.parents_account === undefined) ? (
                  <FormHelperText error id="outlined-adornment-password">
                    require!
                  </FormHelperText>
                ) : null}
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography className="field-title">
                  {t("thead_description")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("labe_description")}
                  {...getFieldProps("account_description")}
                  error={Boolean(
                    touched.account_description && errors.account_description
                  )}
                  helperText={
                    touched.account_description && errors.account_description
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
