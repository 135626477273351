import { gql } from "@apollo/client";

export const LOGIN_SHCEMA = gql`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      is_success
      message
      token
      data {
        user_last_name
        user_first_name
        user_email
        role
        _id
        user_image_name
        user_image_src
        status
        departments_access {
          _id
          department_name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
