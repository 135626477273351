import React, { useState } from "react";
import { IconButton, Stack, Box } from "@mui/material";
//Icons
import { MdModeEdit, MdDelete } from "react-icons/md";
//Srcs
import "../../Style/actionstyle.scss";
import UpdateGeneralJournal from "./UpdateGeneralJournal";
import DeleteGeneralJournal from "./DeleteGeneralJournal";

export default function GeneralJournalAction({
  editData,
  setRefetch,
  userLogin,
}) {
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // console.log("editData::", editData);

  return (
    <Stack direction="row" justifyContent="right">
      {editData?.isClosedRepord ? null : (
        <>
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <MdModeEdit className="update-icon" />
            </Box>
          </IconButton>
          <IconButton onClick={handleOpenDelete}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </>
      )}

      <UpdateGeneralJournal
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
        userLogin={userLogin}
      />
      <DeleteGeneralJournal
        open={openDelete}
        handleClose={handleCloseDelete}
        editData={editData}
        setRefetch={setRefetch}
      />
    </Stack>
  );
}
