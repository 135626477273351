import React, { useState, useEffect, useContext } from "react";
import "./topnavbar.scss";
import {
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Box,
  Typography,
  Tooltip,
  Drawer,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
//Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IoMdMenu } from "react-icons/io";
import { AiOutlineSetting } from "react-icons/ai";
//Srcs
import MenuNavbarMobile from "./MenuNavbarMobile";
import CamFlag from "../../Assets/Cambodia.png";
import EngFlag from "../../Assets/United_Kingdom.png";
import { SelectDepartment } from "../Include/DynamicSelect";
import { AuthContext } from "../../Context/AuthContext";
import LogoutMenu from "./LogoutMenu";

export default function TopNavbar({ open, handleDrawerOpen }) {
  // =================== Change Language =====================
  const { changeLanguage } = useContext(AuthContext);
  const drawerWidth = 280;
  const [departmentVal, setDepartmentVal] = useState(
    JSON.parse(window.localStorage.getItem("departmentVal"))
  );

  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  useEffect(() => {
    if (departmentVal?.id !== "" || departmentVal?.id !== undefined) {
      window.localStorage.setItem("departmentVal", JSON.stringify(departmentVal));
    }
  }, [departmentVal?.id]);

  // ===================== Resize width =========================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // ===================== End Resize width =========================

  const [state, setState] = useState({ left: false });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // ================== Function menu logout ===================
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ====================== Function open logout =======================
  const [oepnLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => {
    setOpenLogout(true);
    handleClose();
  };
  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  // ======================= Change language function =======================
  const [langauge, setLanguage] = useState("en");
  const handleChangeLanguage = (lang) => {
    window.localStorage.setItem("language", lang);
    setLanguage(lang);
    changeLanguage(lang);
  };

  // useEffect(() => {
  //   let getLaung = window.localStorage.getItem("language");
  //   if (getLaung) {
  //     setLanguage(getLaung);
  //     changeLanguage(getLaung);
  //     return;
  //   } else {
  //     setLanguage("en");
  //     changeLanguage("en");
  //     window.localStorage.setItem("language", "en");
  //     return;
  //   }
  // }, []);

  return (
    <Toolbar className="top-bar">
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%", height: "60px" }}
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={width > 1400 ? 30 : 1}>
          <Stack direction="column" justifyContent="center">
            <IconButton
              onClick={toggleDrawer("left", true)}
              edge="start"
              sx={{
                display: open ? "flex" : "none",
                bgcolor: "#027816 !important",
              }}
            >
              <IoMdMenu style={{ color: "#fff" }} />
            </IconButton>

            <Drawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
            >
              <Box
                sx={{ width: drawerWidth }}
                role="presentation"
                onKeyDown={toggleDrawer("left", false)}
              >
                <MenuNavbarMobile toggleDrawer={toggleDrawer("left", false)} />
              </Box>
            </Drawer>
          </Stack>

          <Stack
            direction="column"
            justifyContent="center"
            className="select-container"
          >
            <Stack direction="row" spacing={1}>
              <Stack
                direction="column"
                justifyContent="center"
                className="access-text"
              >
                Access :
              </Stack>
              <SelectDepartment
                selectedVal={departmentVal}
                setSelectedVal={setDepartmentVal}
                className={"text-field"}
                type={"TopNavbar"}
              />
            </Stack>
          </Stack>
        </Stack>
        {/* <Box sx={{ flexGrow: 1 }} /> */}

        <Stack direction="row" spacing={1.4} justifyContent="right">
          <Stack direction="column" justifyContent="center">
            <Tooltip title="Setting" arrow>
              <Link to="/setting">
                <IconButton>
                  <AiOutlineSetting className="link-icon" />
                </IconButton>
              </Link>
            </Tooltip>
          </Stack>

          {/* <Stack direction="column" justifyContent="center">
            {langauge === "en" ? (
              <Tooltip title="Change to Khmer" placement="left">
                <IconButton onClick={() => handleChangeLanguage("kh")}>
                  <Avatar
                    src={EngFlag}
                    sx={{ width: "25px", height: "25px" }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Change to English" placement="left">
                <IconButton onClick={() => handleChangeLanguage("en")}>
                  <Avatar
                    src={CamFlag}
                    sx={{ width: "25px", height: "25px" }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack> */}

          <Stack direction="column" justifyContent="center">
            <Button className="btn-menu" onClick={handleClick}>
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <Avatar src="" alt="User" className="avatar-user" />
                </Stack>
                <Stack direction="column" justifyContent="center">
                  <Typography className="username-text">
                    {userLogin !== undefined && userLogin !== ""
                      ? userLogin?.user_first_name +
                        " " +
                        userLogin?.user_last_name
                      : "User Login"}
                  </Typography>
                </Stack>
                <Stack direction="column" justifyContent="center">
                  {openMenu ? (
                    <ArrowDropUpIcon className="icon-menu" />
                  ) : (
                    <ArrowDropDownIcon className="icon-menu" />
                  )}
                </Stack>
              </Stack>
            </Button>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleOpenLogout}>Logout </MenuItem>
            </Menu>
            <LogoutMenu open={oepnLogout} handleClose={handleCloseLogout} />
          </Stack>
        </Stack>
      </Stack>
    </Toolbar>
  );
}
