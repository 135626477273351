import React, { useState } from "react";
import {
  TextField,
  IconButton,
  TableRow,
  TableCell,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//Icons
import DeleteIcon from "@mui/icons-material/Delete";
//Srcs
import { GET_LIST_OF_CHARTACCOUNT } from "../../Schema/ChartOfAccount";

export default function ListJournalEntry({
  deleteJournalEntryList,
  journalEntryList,
  setUpdateDebit,
  setUpdateCredit,
  setUpdateDescription,
  requiredField,
  setUpdateChartAccount,
}) {
  const [selectOptions, setSelectOptions] = useState([]);

  const [touched, setTouched] = useState(false);
  const handleTouch = () => setTouched(true);

  const { data, refetch } = useQuery(GET_LIST_OF_CHARTACCOUNT, {
    variables: {
      departmentId: [],
      accountType: [],
    },
    onCompleted: ({ getChartOfAccountList }) => {
      let rows = [];
      // console.log("getChartOfAccountList::", getChartOfAccountList);
      if (getChartOfAccountList?.length !== 0) {
        getChartOfAccountList?.map((d) => {
          const allRows = {
            id: d?._id,
            title:
              d?.parents_account === null
                ? d?.account_name
                : d?.parents_account?.account_name + ": " + d?.account_name,
          };
          rows?.push(allRows);
          setSelectOptions(rows);
        });
      }
    },
    onError: (error) => {
      console.log("Error: ", error?.message);
    },
  });

  const Listitems = journalEntryList?.map((item, index) => {
    // console.log("item", item);
    return (
      <TableRow
        key={item?.key}
        className="table-head"
        sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px" }}
      >
        <TableCell className="thead-title-start" width="35%">
          <Autocomplete
            value={{
              id: item?.chart_account_id,
              title: item?.chart_account_name,
            }}
            options={selectOptions}
            onChange={(event, val) => {
              setUpdateChartAccount(val?.id, val?.title, item?.key);
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) =>
              option.title === undefined ? "" : option.title
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="select"
                onFocus={handleTouch}
                error={
                  (touched && item?.chart_account_name === undefined) ||
                  (requiredField && item?.chart_account_name === "") ||
                  (requiredField && item?.chart_account_name === undefined)
                }
              />
            )}
          />
        </TableCell>
        <TableCell className="thead-title" width="30%">
          <TextField
            fullWidth
            size="small"
            value={item?.description}
            placeholder="description"
            onChange={(e) => {
              setUpdateDescription(e?.target?.value, item?.key);
            }}
          />
        </TableCell>
        <TableCell className="thead-title" width="15%">
          <TextField
            fullWidth
            size="small"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">$</InputAdornment>,
              inputProps: {
                min: 0,
              },
            }}
            value={item?.debit}
            onChange={(e) => {
              setUpdateDebit(e?.target?.value, item?.key);
            }}
            onFocus={handleTouch}
            error={
              (touched && isNaN(item?.debit)) ||
              (requiredField && item?.debit === undefined) ||
              (requiredField && item?.debit === "")
            }
          />
        </TableCell>
        <TableCell className="thead-title" width="15%">
          <TextField
            fullWidth
            type="number"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">$</InputAdornment>,
              inputProps: {
                min: 0,
              },
            }}
            value={item?.credit}
            onChange={(e) => {
              setUpdateCredit(e?.target?.value, item?.key);
            }}
            onFocus={handleTouch}
            error={
              (touched && isNaN(item?.credit)) ||
              (requiredField && item?.credit === undefined) ||
              (requiredField && item?.credit === "")
            }
          />
        </TableCell>
        <TableCell className="thead-title-end" width="5%">
          <IconButton onClick={() => deleteJournalEntryList(item?.key)}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
  return Listitems;
}
