import { gql } from "@apollo/client";

export const GET_SUMMARY_INCOME_STATEMENT = gql`
  query GetSummaryIncomeStatment(
    $departmentId: String
    $fromDate: String
    $toDate: String
  ) {
    getSummaryIncomeStatment(
      department_id: $departmentId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      revenue
      costOfSale
      Expense
      grossProfit
      netIncome
    }
  }
`;

export const GET_TOTAL_CASH = gql`
  query GetCash($departmentId: String, $fromDate: String, $toDate: String) {
    getCash(
      department_id: $departmentId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      cashOnHand
      cashInBank
    }
  }
`;

export const GET_AR_AND_AP = gql`
  query GetARandAP($fromDate: String, $toDate: String) {
    getARandAP(fromDate: $fromDate, toDate: $toDate) {
      month
      balanceAR
      balanceAP
    }
  }
`;

export const GET_EXPENSE_BY_DEPARTMENT = gql`
  query GetExpenseByDepartment(
    $departmentId: String
    $fromDate: String
    $toDate: String
  ) {
    getExpenseByDepartment(
      department_id: $departmentId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      account_name
      balance
    }
  }
`;

export const GET_REVENUE_BY_DEPARTMENT = gql`
  query GetRevenueByDepartment(
    $departmentId: String
    $fromDate: String
    $toDate: String
  ) {
    getRevenueByDepartment(
      department_id: $departmentId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      account_name
      balance
    }
  }
`;

export const GET_COSTSALE_BY_DEPARTMENT = gql`
  query GetCostOfSaleByDepartment(
    $departmentId: String
    $fromDate: String
    $toDate: String
  ) {
    getCostOfSaleByDepartment(
      department_id: $departmentId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      account_name
      balance
    }
  }
`;
